import { ReactComponent as IconBox } from '@a/icons/arrow-box.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ElementType } from 'react';
import PlainBtn from './PlainBtn';

const Btn = styled(PlainBtn)<{ dark?: boolean; white?: boolean }>`
    ${({ dark, theme, white }) =>
        dark || white
            ? css`
                  color: ${dark ? theme.colors.dark : theme.colors.white};

                  > svg {
                      circle,
                      path {
                          stroke: ${dark ? theme.colors.dark : theme.colors.white};
                      }

                      path {
                          fill: ${dark ? theme.colors.dark : theme.colors.white};
                      }
                  }
              `
            : css`
                  background-image: ${theme.colors.gradient};
                  background-clip: text;
                  text-fill-color: transparent;
              `};

    text-transform: capitalize;

    > svg {
        margin-left: 16px;

        circle {
            transition: stroke 0.3s ease-in-out;
        }

        path {
            transition: stroke 0.3s ease-in-out;
        }
    }

    :hover {
        > svg {
            path {
                transform: scaleX(1.3) translateX(-5px);
            }
        }
    }

    :focus-visible {
        > svg {
            path {
                transform: scaleX(1.3) translateX(-5px);
            }
        }
    }
`;

type ArrowProps = React.HTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    as?: ElementType;
    to?: string;
    dark?: boolean;
    white?: boolean;
};

export default function ArrowBtn({ children, ...props }: ArrowProps) {
    return (
        <Btn {...props}>
            {children} <IconBox />
        </Btn>
    );
}
