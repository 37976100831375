import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import PlainBtn from './PlainBtn';

const Btn = styled(PlainBtn)<{ light?: boolean }>`
    padding: 15px 31px;
    z-index: 1;
    border: 1px solid ${({ theme, light }) => (light ? theme.colors.light : theme.colors.accent)};
    position: relative;
    transition:
        border-color 0.3s ease-in-out,
        background-position 0.3s ease-in-out;

    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.3s ease-in-out;
    }

    ::before {
        background-color: rgba(24, 24, 24, 0.5);
        opacity: ${({ light }) => (light ? 1 : 0)};
    }

    ::after {
        background-image: ${({ theme }) => theme.colors.gradient};
    }

    > span {
        ${flexCenter};
        display: inline-flex;
        background-clip: text;
        text-fill-color: transparent;
        z-index: 2;
        background-image: linear-gradient(
            to bottom,
            #f4f4f4,
            #f4f4f4 33.33333%,
            #e3c071 66.66666%,
            #b08421
        );
        background-size: 100% 300%;
        background-position: ${({ light }) => (light ? 'top left' : 'left 0 top 100%')};

        > img,
        > svg {
            margin-left: 16px;
        }
    }

    :hover {
        > span {
            background-position: top left;
        }

        ::before {
            opacity: 0;
        }

        ::after {
            opacity: 1;
        }
    }

    :focus-visible {
        > span {
            background-position: top left;
        }

        ::before {
            opacity: 0;
        }

        ::after {
            opacity: 1;
        }
    }
`;

type BtnProps = {
    as?: ElementType;
    children: ReactNode;
    light?: boolean;
    to?: string;
    className?: string;
};

export default function PrimaryBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            <span>{children}</span>
        </Btn>
    );
}
