import { PlainBtn } from '@/buttons';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { Script } from 'gatsby';
import { useEffect, useState } from 'react';

const Div = styled.div`
    ${flexCenter};
    margin-left: auto;

    @media (min-width: 1280px) {
        margin-left: 0;
    }
`;

const Btn = styled(PlainBtn)<{ active?: boolean }>`
    padding: 4px 8px;
    line-height: 229%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.white};
    background-image: linear-gradient(
        to bottom,
        rgba(24, 24, 24, 0.5),
        rgba(24, 24, 24, 0.5) 33.33333%,
        #e3c071 66.66666%,
        #b08421
    );
    background-size: 100% 300%;
    background-position: top left;
    font-family: 'Montserrat', sans-serif;
    box-shadow: ${({ active }) => (active ? 'inset 0 0 0 2px #E3C071' : 'none')};
    transition: background-position 0.3s ease-in-out;

    :hover {
        background-position: left 0 top 100%;
    }

    :focus-visible {
        background-position: left 0 top 100%;
    }
`;

export const LangToggle = () => {
    const [lang, setLang] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLang(document.cookie.includes('googtrans=/en/es') ? 'es' : 'en');
    }, []);

    const handleLang = (lang: string) => () => {
        const date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        document.cookie = `googtrans=/en/${lang};domain=.${
            window.location.hostname
        };expires=${date.toUTCString()};path=/`;
        document.cookie = `googtrans=/en/${lang};expires=${date.toUTCString()};path=/`;
        window.location.reload();
    };

    return (
        <Div>
            <Btn
                onClick={handleLang('en')}
                active={lang === 'en'}
                aria-label="change website language to english"
            >
                ENG
            </Btn>
            <Btn
                onClick={handleLang('es')}
                active={lang === 'es'}
                aria-label="change website language to spanish"
            >
                ESP
            </Btn>
            <Script
                src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                type="text/javascript"
                strategy="idle"
                onLoad={() => setLoaded(true)}
            />

            {loaded ? (
                <Script id="google-translate">{`function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element');}`}</Script>
            ) : (
                ''
            )}
        </Div>
    );
};
