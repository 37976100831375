import { ContactForm } from '@/ContactForm';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { useRef } from 'react';

const Section = styled.section`
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.colors.light};

    @media (min-width: 768px) {
        display: flex;
    }

    @media (min-width: 1600px) {
        justify-content: center;
    }
`;

const Offer = styled.div`
    padding: 32px 16px;
    background-color: ${({ theme }) => theme.colors.accent};

    > h3 {
        margin: 0;
    }

    > p {
        margin: 32px 0;
    }

    @media (min-width: 768px) {
        padding: 64px 32px;
        width: 300px;
        max-width: 450px;
        height: auto;
    }

    @media (min-width: 1024px) {
        width: 30vw;
        min-width: 300px;
        flex-shrink: 0;
    }
`;

export const FirstTime = () => {
    const firstRef = useRef<HTMLDivElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    return (
        <Section>
            <Offer ref={firstRef} className={`reveal-left${firstReveal ? ' revealed' : ''}`}>
                <h3>First Time?</h3>
                <p>Let’s make things easy before you even step into our office.</p>
                {/* <ArrowBtn dark as={CLink} to={getNavLink('book appointment')}>
                    Patient Forms
                </ArrowBtn> */}
            </Offer>
            <ContactForm />
        </Section>
    );
};
