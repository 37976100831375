type Seo = {
    [index: string]: {
        t: string;
        d?: string;
    };
};

export const seoData: Seo = {
    'contact-us': {
        t: 'Contact Us - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Getting your perfect smile is only an appointment away! We will provide a detailed consultation to ensure your treatments are effective and budget friendly.',
    },
    'about-us': {
        t: 'About Us - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Welcome to Serenity Advanced Dentistry! Our Advanced Dentistry team is dedicated to providing you and your family with professional and personal care that’s designed to make you feel comfortable and safe.',
    },
    'our-doctors': {
        t: 'Our Doctors - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Our doctors are dedicated to providing you and your family with professional and personal care that’s designed to make you feel comfortable and safe.',
    },
    'our-practice': {
        t: 'Experience Our Place - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'We made it our mission to make sure you are comfortable as soon you step into our office. With a boutique experience for each and everyone who walks, to comfortable seats with blankets and TVs for distraction, we got all our bases covered.',
    },
    membership: {
        t: 'The Serenity Membership - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Please don’t take chances with your health, call Serenity Advanced Dentistry today and find out how we can help you have optimal oral health.',
    },
    'sedation-dentistry': {
        t: 'Sedation Dentistry Services - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Sedation Dentistry offers anxiety-free dental procedures as the patient is in a deeply relaxed state, though fully responsive.',
    },
    'general-dentistry': {
        t: 'Advanced General Dentistry - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Serenity Advanced Dentistry always welcomes new patients, so feel free to call our office to make an appointment or just to introduce yourself.',
    },
    'dental-crowns': {
        t: 'Dental Crown Services - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'At Serenity Advanced Dentistry, we offer dental crowns! By using an advanced technology we are able to design high-quality dental crowns in our office at a reasonable price range.',
    },
    dentures: {
        t: 'Same Day Dentures Services - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Same-day dentures are a super easy and convenient way to get your damaged dentures in a day. Sedation Dentistry offers stress-free dental procedures to their patients..',
    },
    'dental-emergency-treatment': {
        t: 'Dental Emergency Treatment - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Emergency dental care treatment in Cedar Hill at Serenity Advanced Dentistry Dental Group. If you need immediate assistance, call for a same-day appointment.',
    },
    'oral-surgery': {
        t: 'Emergency Advanced Oral Surgery - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Serenity Advanced Dentistry in Cedar Hill, TX provides Emergency Advanced Oral Surgery Procedures, Including Dental Implants and Wisdom Teeth Removal.',
    },
    'dental-implants': {
        t: 'Dental Implants Services - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Our Dental implants services in Cedar Hill, TX give you a healthy, comfortable, confident smile. They fit, feel, and function just like your own natural teeth.',
    },
    'root-canals': {
        t: 'Root Canals - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Root canal is a dental procedure that relieves pain caused by an infected or abscessed tooth. Visit Serenity Advanced Dentistry at Cedar Hill, TX.',
    },
    'teeth-whitening': {
        t: 'Teeth Whitening - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Our Teeth whitening is one of the most popular cosmetic dentistry In Cedar Hill, TX providing a quick, non-invasive and affordable way to enhance a smile.',
    },
    extractions: {
        t: 'Pain Free Tooth Extractions - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Dr. Allala provides extractions ranging from simple tooth removal to surgical removal. She focuses on a comfortable, pain-free experience for patients facing this often unsettling procedure.',
    },
    'clear-aligners-and-clear-braces': {
        t: 'Clear Aligners & Clear Braces - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Straighten your teeth and revolutionize your smile in six months with dr. Allala',
    },
    volunteering: {
        t: 'Volunteering - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Serenity Advanced Dentistry Is Proudly Partnered With Tri-City Animal Shelter & Adoption Center.',
    },
    testimonials: {
        t: 'Testimonials - Serenity Advanced Dentistry - Cedar Hill, TX',
        d: 'Please watch testimonial videos of our happy customers. Your feedback is important for us.',
    },
    'cedar-hill-dentist': {
        t: 'Dentist in Cedar Hill',
        d: 'Serenity Advanced Dentistry is proud to be recognized as the top dentist in Cedar Hill. Our commitment to providing exceptional dental care has made us a trusted choice for patients seeking a dentist near me in the Cedar Hill area.',
    },
    'dental-implants-cedar-hill': {
        t: 'Dental Implants in Cedar Hill',
        d: 'Serenity Advanced Dentistry is proud to be the top implant dentist in Cedar Hill, offering exceptional dental implant services to patients seeking dental implants near me in the Cedar Hill area.',
    },
    'emergency-dentist-cedar-hill': {
        t: 'Emergency Dentist in Cedar Hill',
        d: 'At Serenity Advanced Dentistry in Cedar Hill, we understand that dental emergencies can happen at any time.  That’s why we are proud to offer emergency dental services to patients in need.',
    },
    'sedation-dentistry-cedar-hill': {
        t: 'Sedation Dentistry in Cedar Hill',
        d: 'At Serenity Advanced Dentistry in Cedar Hill, we understand that dental anxiety can be a significant barrier to receiving necessary dental care.',
    },
};
