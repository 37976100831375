import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { CLink } from './CLink';
import {
    Error,
    FormButtons,
    FormFlex,
    Input,
    Label,
    SubmitBtn,
    TextField,
    Thanks,
} from './FormComponents';
import { ArrowBtn } from './buttons';
import { siteInfo } from './layout/NavLinks';
import { py } from './layout/styles/classes';

export const Form = styled.form`
    ${py};
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    max-width: 500px;
    scroll-margin-top: 85px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.light};

    > h2 {
        margin: 0 0 32px;
        text-fill-color: transparent;
        background-clip: text;
        background-image: ${({ theme }) => theme.colors.gradient};
    }

    @media (min-width: 768px) {
        width: 72%;
        margin: 0;
        max-width: 700px;
    }

    @media (min-width: 1024px) {
        padding-left: 32px;
        padding-right: 32px;
        box-sizing: content-box;
        scroll-margin-top: 96px;

        ${TextField} {
            :last-of-type {
                margin-bottom: 0;
            }
        }
    }
`;

export const ContactForm = () => {
    const [submit, setSubmit] = useState<'init' | 'submitting' | 'submitted' | 'error'>('init');

    const { landingPage } = useLocationData();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit('submitting');

        const data = Object.fromEntries(new FormData(e.currentTarget));

        const formData = {
            ...data,
            landingPage,
        };

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(() => {
                setSubmit('submitted');
            })
            .catch(() => {
                setSubmit('error');
            });
    };

    const firstRef = useRef<HTMLFormElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    return (
        <Form
            onSubmit={handleSubmit}
            id="contact-us"
            ref={firstRef}
            className={`reveal-right${firstReveal ? ' revealed' : ''}`}
        >
            <h2>Request a Call/Text Back</h2>

            <FormFlex>
                <div>
                    <TextField>
                        <Input type="text" id="name" name="name" placeholder="Name" />
                        {/* label should be after input because of sibling css selector being used */}
                        <Label htmlFor="name">
                            <span>Your Name</span>
                        </Label>
                    </TextField>
                    <TextField>
                        <Input
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder="e.g: example@gmail.com"
                        />
                        <Label htmlFor="email">
                            <span>Email address*</span>
                        </Label>
                    </TextField>
                    <TextField>
                        <PatternFormat
                            type="tel"
                            format="+1 (###) ###-####"
                            customInput={Input}
                            placeholder="123 456-7890"
                            id="phone"
                            name="phone"
                            required
                        />
                        <Label htmlFor="phone">
                            <span>Phone Number*</span>
                        </Label>
                    </TextField>
                </div>

                <TextField>
                    <Input
                        as="textarea"
                        rows={7}
                        id="message"
                        name="message"
                        placeholder="Your Message"
                    />
                    <Label htmlFor="message">
                        <span>Message for the team</span>
                    </Label>
                </TextField>
            </FormFlex>
            <FormButtons>
                <SubmitBtn disabled={submit === 'submitting'}>
                    {submit === 'submitting' ? 'Sending...' : 'Send us a Message'}
                </SubmitBtn>
                <ArrowBtn as={CLink} to={landingPage ? siteInfo.phone2.link : siteInfo.phone.link}>
                    Call Us Instead
                </ArrowBtn>
            </FormButtons>

            <Thanks submit={submit === 'submitted'} />
            <Error error={submit === 'error'} />
        </Form>
    );
};
