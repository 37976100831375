import shape from '@a/images/layout/heading-shape.png';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexStart } from './classes';

const styles = css`
    font-family: 'Baskerville ', serif;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 112%;
`;

export const h1 = css`
    ${styles};
    font-size: 2.5rem;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    ${styles};
    font-size: 2rem;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const H2 = styled.h2<{ center?: boolean; noShapes?: boolean }>`
    ${flexStart};
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    background-image: ${({ theme }) => theme.colors.gradient};
    background-clip: text;
    text-fill-color: transparent;
    text-align: ${({ center }) => (center ? 'center' : 'left')};

    ::before,
    ::after {
        content: url(${shape});
        height: 64px;
        left: 0;
        display: ${({ noShapes }) => (noShapes ? 'none' : 'block')};
    }

    ::before {
        top: 0;
        transform: scaleY(-1);
    }

    ::after {
        bottom: 0;
    }
`;

export const h3 = css`
    ${styles};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h4 = css`
    ${styles};
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;
`;
