import { h2 } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { PrimaryBtn } from './buttons';
import { flexCenter, flexSpace } from './layout/styles/classes';

export const TextField = styled.div`
    min-height: 56px;
    position: relative;
    transition: background-color 0.15s linear;
    display: flex;
    margin-bottom: 20px;

    :focus-within {
        > label {
            transform: translateY(-155%);

            > span {
                background-position: left 0 top 100%;
            }
        }
    }
`;

// floating label
export const Label = styled.label`
    position: absolute;
    left: 16px;
    top: 20px;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
    line-height: 1.2rem;
    background-color: ${({ theme }) => theme.colors.light};
    padding: 0 4px;

    > span {
        font-weight: 500;
        transition: background-position 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        text-transform: capitalize;
        text-fill-color: transparent;
        background-clip: text;
        background-image: linear-gradient(
            to bottom,
            #8f8f8f,
            #8f8f8f 33.33333%,
            #e3c071 66.66666%,
            #b08421
        );
        background-size: 100% 300%;
        background-position: top left;
    }
`;

export const Input = styled.input<{ rows?: number }>`
    text-transform: capitalize;
    text-fill-color: transparent;
    background-clip: text;
    background-image: ${({ theme }) => theme.colors.gradient};
    width: 100%;
    padding: 16px;
    display: block;
    outline: none;
    transition: border-color 0.15s ease-in;
    font-size: 1rem;
    line-height: 162%;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(0deg, #8f8f8f, #8f8f8f);
    transition: border-image-source 0.15s ease-in;
    resize: none;

    :hover,
    :focus {
        border-image-source: ${({ theme }) => theme.colors.gradient};
    }

    ::placeholder {
        visibility: hidden;
        opacity: 0;
        display: none;
    }

    /* if there is text in input */
    :not(:placeholder-shown) + label {
        transform: translateY(-155%);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        transition:
            background-color 5000s ease-in-out 0s,
            color 5000s ease-in-out 0s;
        text-fill-color: ${({ theme }) => theme.colors.accent};
        -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.light} inset;
        transition: background-color 50000s ease-in-out 0s;
    }

    :focus:invalid {
        border-color: #e75842;
    }
`;

export const FormFlex = styled.div`
    @media (min-width: 1024px) {
        ${flexCenter};
        align-items: stretch;

        > div {
            :first-of-type {
                width: 50%;
                margin-right: ${({ theme }) => theme.spacing.md};
                flex-shrink: 0;
            }

            :last-of-type {
                width: 50%;
            }
        }
    }
`;

export const SubmitBtn = styled(PrimaryBtn)`
    display: flex;
    width: 100%;
    margin-top: 32px;
    border-width: 2px;
    font-size: 1.25rem;

    ::before {
        background-color: ${({ theme }) => theme.colors.dark};
        opacity: 1;
    }

    @media (min-width: 1024px) {
        max-width: max-content;
        margin-left: auto;
    }

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const FormButtons = styled.div`
    margin-top: 40px;

    > a {
        margin: 24px 0 0;
    }

    > button {
        margin: 0;
    }

    @media (min-width: 500px) {
        ${flexSpace};
        justify-content: flex-end;

        > a {
            margin: 0 64px 0 0;
        }

        > button {
            width: max-content;
            order: 1;
        }
    }
`;

const StyledMessage = styled.div<{ show: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ show: submit }) => (submit ? 10 : -1)};
    opacity: ${({ show: submit }) => (submit ? 1 : 0)};
    transform: ${({ show: submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.light};

    > h3 {
        ${h2};
        margin-top: 0;
    }

    > p {
        margin-bottom: 0;
        font-size: 1.25rem;
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;

export const Thanks = ({ submit }: { submit: boolean }) => (
    <StyledMessage show={submit}>
        <h3>Thank you</h3>
        <p>Someone will be right with you shortly!</p>
    </StyledMessage>
);

export const Error = ({ error }: { error: boolean }) => (
    <StyledMessage show={error}>
        <h3>Something went wrong</h3>
        <p>Please give us a call instead.</p>
    </StyledMessage>
);
