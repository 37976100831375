import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import addressIcon from '@a/icons/address.svg';
import mailIcon from '@a/icons/email.svg';
import phoneIcon from '@a/icons/phone-gradient.svg';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { StaticImage } from 'gatsby-plugin-image';
import { useRef } from 'react';
import { getNavLink, siteInfo } from './NavLinks';
import { H2 } from './styles/Typography';
import { flexCenter, px, py } from './styles/classes';

const Section = styled.section`
    ${py};
    ${px};
    position: relative;
    z-index: 1;

    > h2 {
        margin: 0 0 32px;
    }

    > .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    @media (min-width: 1024px) and (max-width: 1599px) {
        ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 522px;
            width: 30vw;
            min-width: 300px;
            max-width: 450px;
            background-color: ${({ theme }) => theme.colors.accent};
            z-index: 1;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

const Map = styled.iframe`
    width: 100%;
    height: calc(100vw - 32px);
    max-width: 700px;
    max-height: 700px;
    border: none;
    border: 0;
    display: block;
    margin-bottom: 32px;

    @media (min-width: 1024px) {
        z-index: 2;
        margin-bottom: 0;
        margin-right: ${({ theme }) => theme.spacing.md};
    }
`;

const Hours = styled.div`
    > p {
        display: flex;
        margin: 0 0 10px;
        color: ${({ theme }) => theme.colors.light};

        > span:first-of-type {
            min-width: 9ch;
            margin-right: 32px;
        }

        :nth-last-of-type(2),
        :last-of-type {
            > span {
                color: #b1b1b1;
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }
`;

const Info = styled.div`
    max-width: 420px;
    border-top: 1px solid;
    border-image-source: ${({ theme }) => theme.colors.gradient};
    border-image-slice: 1;
    padding-top: 32px;
    margin: 32px 0;

    > a {
        ${flexCenter};
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 32px;
        justify-content: flex-start;
        width: fit-content;
        word-break: break-word;
        background-image: linear-gradient(
            to bottom,
            #f4f4f4,
            #f4f4f4 33.33333%,
            #e3c071 66.66666%,
            #b08421
        );
        background-size: 100% 300%;
        background-position: left 0 top 100%;
        transition: background-position 0.3s ease-in-out, transform 0.3s ease-in-out,
            opacity 0.3s ease-in-out;

        > .gatsby-image-wrapper {
            filter: brightness(0) invert(1);
        }

        > img {
            margin-right: 32px;
            flex-shrink: 0;
        }

        :last-of-type {
            margin-bottom: 0;
        }

        :hover,
        :focus {
            background-position: top left;
        }
    }
`;

export const ContactUs = () => {
    const firstRef = useRef<HTMLParagraphElement | null>(null);
    const firstReveal = useIntersectionObserver(firstRef, {});

    const secondRef = useRef<HTMLParagraphElement | null>(null);
    const secondReveal = useIntersectionObserver(secondRef, {});

    const thirdRef = useRef<HTMLParagraphElement | null>(null);
    const thirdReveal = useIntersectionObserver(thirdRef, {});

    const fourthRef = useRef<HTMLParagraphElement | null>(null);
    const fourthReveal = useIntersectionObserver(fourthRef, {});

    const fifthRef = useRef<HTMLParagraphElement | null>(null);
    const fifthReveal = useIntersectionObserver(fifthRef, {});

    const sixthRef = useRef<HTMLParagraphElement | null>(null);
    const sixthReveal = useIntersectionObserver(sixthRef, {});

    const seventhRef = useRef<HTMLParagraphElement | null>(null);
    const seventhReveal = useIntersectionObserver(seventhRef, {});

    const eighthRef = useRef<HTMLAnchorElement | null>(null);
    const eighthReveal = useIntersectionObserver(eighthRef, {});

    const ninthRef = useRef<HTMLAnchorElement | null>(null);
    const ninthReveal = useIntersectionObserver(ninthRef, {});

    const tenthRef = useRef<HTMLAnchorElement | null>(null);
    const tenthReveal = useIntersectionObserver(tenthRef, {});

    const { landingPage } = useLocationData();

    return (
        <Section>
            <H2 center>We Can’t Wait To See You</H2>
            <Flex>
                <Map
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d428677.3611950481!2d-97.28440647711287!3d32.923509895930465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864eeb2c5ea55555%3A0x1aecfbed0cbc1c90!2sSerenity%20Advanced%20Dentistry%2C%20Dr%20C%20Allala!5e0!3m2!1sen!2s!4v1669530530718!5m2!1sen!2s"
                    id="map"
                    title="map"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
                <div>
                    <Hours>
                        <p ref={firstRef} className={`reveal${firstReveal ? ' revealed' : ''}`}>
                            <span>Monday</span>
                            <span>8AM-5PM</span>
                        </p>
                        <p
                            ref={secondRef}
                            className={`reveal reveal-delay-1${secondReveal ? ' revealed' : ''}`}
                        >
                            <span>Tuesday</span>
                            <span>8AM-5PM</span>
                        </p>
                        <p
                            ref={thirdRef}
                            className={`reveal reveal-delay-2${thirdReveal ? ' revealed' : ''}`}
                        >
                            <span>Wednesday</span>
                            <span>8AM-5PM</span>
                        </p>
                        <p
                            ref={fourthRef}
                            className={`reveal reveal-delay-3${fourthReveal ? ' revealed' : ''}`}
                        >
                            <span>Thursday</span>
                            <span>8AM-5PM</span>
                        </p>
                        <p
                            ref={fifthRef}
                            className={`reveal reveal-delay-4${fifthReveal ? ' revealed' : ''}`}
                        >
                            <span>Friday</span>
                            <span>8AM-5PM</span>
                        </p>
                        <p
                            ref={sixthRef}
                            className={`reveal reveal-delay-5${sixthReveal ? ' revealed' : ''}`}
                        >
                            <span>Saturday</span>
                            <span>Closed</span>
                        </p>
                        <p
                            ref={seventhRef}
                            className={`reveal reveal-delay-6${seventhReveal ? ' revealed' : ''}`}
                        >
                            <span>Sunday</span>
                            <span>Closed</span>
                        </p>
                    </Hours>
                    <Info>
                        <a
                            href={siteInfo.address.link}
                            ref={eighthRef}
                            className={`reveal reveal-delay-7${eighthReveal ? ' revealed' : ''}`}
                        >
                            <img src={addressIcon} alt="address" width={32} height={32} />{' '}
                            {siteInfo.address.text}
                        </a>
                        <a
                            href={landingPage ? siteInfo.phone2.link : siteInfo.phone.link}
                            ref={ninthRef}
                            className={`reveal reveal-delay-8${ninthReveal ? ' revealed' : ''}`}
                        >
                            <img src={phoneIcon} alt="phone" width={32} height={32} />{' '}
                            {landingPage ? siteInfo.phone2.text : siteInfo.phone.text}
                        </a>
                        <a
                            href={siteInfo.email.link}
                            ref={tenthRef}
                            className={`reveal reveal-delay-9${tenthReveal ? ' revealed' : ''}`}
                        >
                            <img src={mailIcon} alt="mail" width={32} height={32} />{' '}
                            {siteInfo.email.text}
                        </a>
                    </Info>
                    <PrimaryBtn as={CLink} to={getNavLink('book an appointment')}>
                        Book Now
                    </PrimaryBtn>
                </div>
            </Flex>
            <StaticImage src="../../../assets/images/dark-bg.jpg" alt="" layout="fullWidth" />
        </Section>
    );
};
