export const siteInfo = {
    phone: {
        text: '(972) 634-1434',
        link: 'tel:+19726341434',
    },
    phone2: {
        text: '(972) 910-7202',
        link: 'tel:+19729107202',
    },
    fax: {
        text: '(214) 935-5669',
        link: 'fax:+12149355669',
    },
    email: {
        link: 'mailto:hello@theserenitydentist.com',
        text: 'hello@theserenitydentist.com',
    },
    address: {
        text: '605 E. Belt Line Rd #101 Cedar Hill, TX 75104',
        link: 'https://goo.gl/maps/vFNRbuJQxReecy5WA',
    },
    social: {
        facebook: 'https://www.facebook.com/ddsallala/',
        instagram: 'https://www.instagram.com/theserenitydentist/',
        tiktok: 'https://www.tiktok.com/@theserenitydentist',
        google: 'https://www.google.com/search?q=serenity+dentist+cedar+hill#lrd=0x864eeb2c5ea55555:0x1aecfbed0cbc1c90,1,,,,',
        youtube: 'https://www.youtube.com/channel/UCpr6QhSPSRoLefS-_r5y1LA',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'practice',
        link: '/practice/',
        links: [
            {
                text: 'about us',
                link: '/practice/about-us/',
            },
            {
                text: 'our doctors',
                link: '/practice/our-doctors/',
            },
            {
                text: 'our practice',
                link: '/practice/our-practice/',
            },
            {
                text: 'technology',
                link: '/practice/technology/',
            },
            {
                text: 'testimonials',
                link: '/practice/testimonials/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
        ],
    },
    {
        text: 'services',
        link: '/services/',
        links: [
            {
                text: 'sedation dentistry',
                link: '/services/sedation-dentistry/',
            },
            {
                text: 'general dentistry',
                link: '/services/general-dentistry/',
            },
            {
                text: 'dental crowns',
                link: '/services/dental-crowns/',
            },
            {
                text: 'dentures',
                link: '/services/dentures/',
            },
            {
                text: 'dental emergency treatment',
                link: '/services/dental-emergency-treatment/',
            },
            {
                text: 'oral surgery',
                link: '/services/oral-surgery/',
            },
            {
                text: 'dental implants',
                link: '/services/dental-implants/',
            },
            {
                text: 'root canals',
                link: '/services/root-canals/',
            },
            {
                text: 'teeth whitening',
                link: '/services/teeth-whitening/',
            },
            {
                text: 'extractions',
                link: '/services/extractions/',
            },
            {
                text: 'clear aligners & clear braces',
                link: '/services/clear-aligners-and-clear-braces/',
            },
            {
                text: 'gum depigmentation',
                link: '/services/gum-depigmentation/',
            },
            {
                text: 'gum grafts',
                link: '/services/gum-grafts/',
            },
            {
                text: 'bone grafts',
                link: '/services/bone-grafts/',
            },
            {
                text: 'non-surgical periodontal therapies',
                link: '/services/non-surgical-periodontal-therapies/',
            },
            {
                text: 'surgical periodontal therapy',
                link: '/services/surgical-periodontal-therapy/',
            },
            {
                text: 'sinus lift',
                link: '/services/sinus-lift/',
            },
        ],
    },
    {
        text: 'membership',
        link: '/membership/',
    },
    {
        text: 'volunteering',
        link: '/volunteering/',
    },
    {
        text: 'contact us',
        link: '#contact-us',
    },
    {
        text: 'book an appointment',
        link: 'https://flexbook.me/allala',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/practice/about-us/',
    },
    {
        text: 'our doctors',
        link: '/practice/our-doctors/',
    },
    {
        text: 'blog',
        link: '/blog/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
    {
        text: 'sitemap',
        link: '/sitemap-index.xml',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
