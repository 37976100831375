exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cedar-hill-dentist-tsx": () => import("./../../../src/pages/cedar-hill-dentist.tsx" /* webpackChunkName: "component---src-pages-cedar-hill-dentist-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dental-implants-cedar-hill-tsx": () => import("./../../../src/pages/dental-implants-cedar-hill.tsx" /* webpackChunkName: "component---src-pages-dental-implants-cedar-hill-tsx" */),
  "component---src-pages-emergency-dentist-cedar-hill-tsx": () => import("./../../../src/pages/emergency-dentist-cedar-hill.tsx" /* webpackChunkName: "component---src-pages-emergency-dentist-cedar-hill-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-practice-about-us-tsx": () => import("./../../../src/pages/practice/about-us.tsx" /* webpackChunkName: "component---src-pages-practice-about-us-tsx" */),
  "component---src-pages-practice-our-doctors-tsx": () => import("./../../../src/pages/practice/our-doctors.tsx" /* webpackChunkName: "component---src-pages-practice-our-doctors-tsx" */),
  "component---src-pages-practice-our-practice-tsx": () => import("./../../../src/pages/practice/our-practice.tsx" /* webpackChunkName: "component---src-pages-practice-our-practice-tsx" */),
  "component---src-pages-practice-technology-tsx": () => import("./../../../src/pages/practice/technology.tsx" /* webpackChunkName: "component---src-pages-practice-technology-tsx" */),
  "component---src-pages-practice-testimonials-tsx": () => import("./../../../src/pages/practice/testimonials.tsx" /* webpackChunkName: "component---src-pages-practice-testimonials-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sedation-dentistry-cedar-hill-tsx": () => import("./../../../src/pages/sedation-dentistry-cedar-hill.tsx" /* webpackChunkName: "component---src-pages-sedation-dentistry-cedar-hill-tsx" */),
  "component---src-pages-volunteering-tsx": () => import("./../../../src/pages/volunteering.tsx" /* webpackChunkName: "component---src-pages-volunteering-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-can-you-be-cured-of-periodontal-disease-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/can-you-be-cured-of-periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-can-you-be-cured-of-periodontal-disease-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-deep-teeth-cleaning-for-periodontal-disease-all-you-need-to-know-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/deep-teeth-cleaning-for-periodontal-disease-all-you-need-to-know/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-deep-teeth-cleaning-for-periodontal-disease-all-you-need-to-know-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-dental-crowns-the-benefits-of-protecting-your-teeth-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/dental-crowns-the-benefits-of-protecting-your-teeth/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-dental-crowns-the-benefits-of-protecting-your-teeth-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-dental-sos-expert-strategies-for-handling-emergencies-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/dental-sos-expert-strategies-for-handling-emergencies/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-dental-sos-expert-strategies-for-handling-emergencies-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-expert-dental-gum-fixes-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/expert-dental-gum-fixes/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-expert-dental-gum-fixes-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-navigating-general-dentistry-and-dental-sedation-at-serenity-advanced-dentistry-in-cedar-hill-tx-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/navigating-general-dentistry-and-dental-sedation-at-serenity-advanced-dentistry-in-cedar-hill-tx/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-navigating-general-dentistry-and-dental-sedation-at-serenity-advanced-dentistry-in-cedar-hill-tx-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-reasons-to-get-dental-implants-more-than-a-smile-makeover-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/reasons-to-get-dental-implants-more-than-a-smile-makeover/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-reasons-to-get-dental-implants-more-than-a-smile-makeover-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-revolutionizing-dental-visits-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/revolutionizing-dental-visits/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-revolutionizing-dental-visits-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-root-canals-debunking-myths-and-embracing-modern-solutions-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/root-canals-debunking-myths-and-embracing-modern-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-root-canals-debunking-myths-and-embracing-modern-solutions-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-unleashing-smiles-easing-fear-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/unleashing-smiles-easing-fear/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-unleashing-smiles-easing-fear-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-why-routine-dental-checkups-are-a-game-changer-for-your-oral-health-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/blogs/why-routine-dental-checkups-are-a-game-changer-for-your-oral-health/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blogs-why-routine-dental-checkups-are-a-game-changer-for-your-oral-health-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafts-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/bone-grafts/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafts-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-clear-aligners-and-clear-braces-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/clear-aligners-and-clear-braces/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-clear-aligners-and-clear-braces-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/dental-crowns/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-emergency-treatment-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/dental-emergency-treatment/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-emergency-treatment-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dentures-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/dentures/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dentures-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-extractions-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/extractions/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-extractions-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-general-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/general-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-general-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-gum-depigmentation-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/gum-depigmentation/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-gum-depigmentation-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-gum-grafts-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/gum-grafts/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-gum-grafts-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-therapies-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/non-surgical-periodontal-therapies/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-therapies-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-oral-surgery-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/oral-surgery/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-oral-surgery-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-root-canals-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/root-canals/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-root-canals-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/sedation-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-sinus-lift-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/sinus-lift/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-sinus-lift-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-surgical-periodontal-therapy-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/surgical-periodontal-therapy/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-surgical-periodontal-therapy-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/serenity/serenity/src/content/services/teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx" */)
}

