import { plainBtnStyles } from '@/buttons/PlainBtn';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';
import { flexSpace } from './styles/classes';

export const navLinkStyles = css`
    ${flexSpace};
    text-transform: capitalize;
    color: ${theme.colors.white};
    padding: 9px 17px;
    position: relative;
    transition: color 0.3s ease-in-out;
    font-weight: 500;
    line-height: 200%;
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;

    ::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-width: 1px;
        border-style: solid;
        border-image-slice: 1;
        border-image-source: ${theme.colors.gradient};
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.white};
        }
    }

    &.current-nav-category,
    &[aria-current='page'] {
        > svg > path {
            fill: ${theme.colors.light};
        }
    }

    &[data-selected],
    :hover {
        color: ${theme.colors.light};
        background-color: ${theme.colors.dark};

        > svg path {
            fill: ${theme.colors.light};
        }

        ::before {
            opacity: 1;
        }
    }

    :focus-visible {
        color: ${theme.colors.light};
        background-color: ${theme.colors.dark};

        > svg path {
            fill: ${theme.colors.light};
        }

        ::before {
            opacity: 1;
        }
    }

    @media (min-width: 1024px) {
        &.current-nav-category,
        &[aria-current='page'] {
            > svg > path {
                fill: ${theme.colors.accent};
            }
        }
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        transform: rotate(90deg);
    }

    :hover {
        color: ${theme.colors.dark};
        background: rgba(244, 244, 244, 0.2);
        backdrop-filter: blur(8px);
    }

    :focus-visible {
        color: ${theme.colors.dark};
        background: rgba(244, 244, 244, 0.2);
        backdrop-filter: blur(8px);
    }

    &[aria-expanded='true'] {
        > svg {
            transform: rotate(270deg);

            path {
                fill: ${theme.colors.dark};
            }
        }
    }
`;

export const subNavBtnStyles = css`
    ${navLinkStyles};
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
    width: 100%;
`;
