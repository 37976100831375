import { CLink } from '@/CLink';
import { PlainBtn } from '@/buttons';
import { ReactComponent as AddressIcon } from '@a/icons/address.svg';
import { ReactComponent as MailIcon } from '@a/icons/email.svg';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as FaxIcon } from '@a/icons/fax.svg';
import { ReactComponent as GoogleIcon } from '@a/icons/google.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone-gradient.svg';
import { ReactComponent as TiktokIcon } from '@a/icons/tiktok.svg';
import { ReactComponent as TopIcon } from '@a/icons/top.svg';
import { ReactComponent as YoutubeIcon } from '@a/icons/youtube.svg';
import mdmLogo from '@a/images/layout/mdm-logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { HomeLogo } from './Nav';
import { footerNavLinks, navLinks, siteInfo } from './NavLinks';
import { flexCenter, flexSpace, npx, px } from './styles/classes';

const Section = styled.footer`
    ${px};
    padding-top: 64px;
    padding-bottom: 20px;
    background-color: ${({ theme }) => theme.colors.dark};

    > a:first-of-type {
        position: static !important;

        > svg {
            width: 250px;
            height: 128px;
            margin: 0 auto;
        }
    }
`;

const Flex = styled.div`
    margin: 64px auto;
    max-width: 1775px;

    h2 {
        margin: 0 0 32px;
        color: ${({ theme }) => theme.colors.light};
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: ${({ theme }) => theme.colors.gradient};
        padding-bottom: 32px;
        max-width: max-content;
    }

    > div:not(:last-of-type) {
        margin-bottom: 64px;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;

        > div:not(:last-of-type) {
            margin-bottom: 0;
        }
    }
`;

export const SiteInfoBox = styled.div`
    > a {
        ${flexCenter};
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 32px;
        justify-content: flex-start;
        width: fit-content;
        word-break: break-word;
        background-image: linear-gradient(
            to bottom,
            #f4f4f4,
            #f4f4f4 33.33333%,
            #e3c071 66.66666%,
            #b08421
        );
        background-size: 100% 300%;
        background-position: left 0 top 100%;
        transition: background-position 0.3s ease-in-out;

        > svg {
            margin-right: 32px;
            flex-shrink: 0;

            path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }

        :hover,
        :focus {
            background-position: top left;
        }
    }
`;

const Links = styled.div<{ mob?: boolean }>`
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 8px 32px;

    > a {
        color: ${({ theme }) => theme.colors.light};
        transition: color 0.3s ease-in-out;
        text-transform: capitalize;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.accent};
        }
    }

    ${({ mob }) =>
        mob &&
        css`
            @media (max-width: 430px) {
                grid-template-columns: max-content;
            }
        `};

    @media (min-width: 1024px) and (max-width: 1279px) {
        grid-template-columns: max-content;
    }
`;

const Flex2 = styled.div`
    ${flexSpace};
    flex-wrap: wrap;
    gap: 32px 64px;
    max-width: 1775px;
    margin: 0 auto 64px;

    > button {
        align-self: flex-end;
    }
`;

const Social = styled.div`
    > h3 {
        margin: 0 0 32px;
        color: ${({ theme }) => theme.colors.light};
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: ${({ theme }) => theme.colors.gradient};
        padding-bottom: 32px;
        max-width: max-content;
    }

    > div {
        display: flex;
        flex-wrap: wrap;

        > a {
            > svg path {
                transition: fill 0.3s ease-in-out;
            }

            :hover,
            :focus {
                > svg path {
                    fill: ${({ theme }) => theme.colors.accent};
                }
            }

            :not(:last-of-type) {
                margin-right: 64px;
            }
        }
    }

    @media (max-width: 430px) {
        svg {
            max-width: 24px;
        }
    }
`;

const Copyright = styled.div`
    ${flexSpace};
    ${px};
    ${npx};
    flex-wrap: wrap;
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: ${({ theme }) => theme.colors.gradient};
    padding-top: 32px;
    color: ${({ theme }) => theme.colors.light};
    gap: 32px;

    > p {
        margin: 0;

        :first-of-type {
            font-size: 0.875rem;
        }
    }

    @media (min-width: 1921px) {
        padding: 32px calc((100vw - 1775px) / 2) 0;
    }
`;

const Power = styled.p`
    ${flexCenter};
    flex-wrap: wrap;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    margin: 0;

    > img {
        margin-right: 32px;
    }

    > a {
        transition: color 0.3s ease-in-out;
        color: ${({ theme }) => theme.colors.light};
        margin-left: 1ch;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.accent};
        }
    }
`;

export const Footer = () => {
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const { landingPage } = useLocationData();

    return (
        <Section>
            <HomeLogo />
            <Flex>
                <SiteInfoBox>
                    <h2>Find Us</h2>
                    <CLink to={siteInfo.address.link}>
                        <AddressIcon /> {siteInfo.address.text}
                    </CLink>
                    <CLink to={landingPage ? siteInfo.phone2.link : siteInfo.phone.link}>
                        <PhoneIcon /> {landingPage ? siteInfo.phone2.text : siteInfo.phone.text}
                    </CLink>
                    <CLink to={siteInfo.fax.link}>
                        <FaxIcon /> {siteInfo.fax.text}
                    </CLink>
                    <CLink to={siteInfo.email.link}>
                        <MailIcon /> {siteInfo.email.text}
                    </CLink>
                </SiteInfoBox>
                <div>
                    <h2>Services</h2>
                    <Links mob>
                        {navLinks[1].links?.map((link, i) => (
                            <CLink to={link.link} key={i}>
                                {link.text}
                            </CLink>
                        ))}
                    </Links>
                </div>
                <div>
                    <h2>Navigate</h2>
                    <Links>
                        {footerNavLinks.map((link, i) => (
                            <CLink to={link.link} key={i}>
                                {link.text}
                            </CLink>
                        ))}
                    </Links>
                </div>
            </Flex>
            <Flex2>
                <Social>
                    <h3>Find Us on Social Media</h3>
                    <div>
                        <CLink
                            to={siteInfo.social.facebook}
                            aria-label="serenity advanced dentistry facebook"
                        >
                            <FacebookIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.instagram}
                            aria-label="serenity advanced dentistry instagram"
                        >
                            <InstagramIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.tiktok}
                            aria-label="serenity advanced dentistry tiktok"
                        >
                            <TiktokIcon width={24} height={24} />
                        </CLink>
                        <CLink
                            to={siteInfo.social.google}
                            aria-label="serenity advanced dentistry google"
                        >
                            <GoogleIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.youtube}
                            aria-label="serenity advanced dentistry youtube"
                        >
                            <YoutubeIcon />
                        </CLink>
                    </div>
                </Social>

                <PlainBtn onClick={handleClick} aria-label="scroll page to top">
                    <TopIcon />
                </PlainBtn>
            </Flex2>

            <Copyright>
                <p>
                    Serenity Advanced Dentistry All rights reserved. &copy;{' '}
                    {new Date().getFullYear()}
                </p>
                <Power>
                    <img src={mdmLogo} alt="modern doc media" />
                    POWERED BY <CLink to="https://moderndocmedia.com/">MODERN DOC MEDIA</CLink>.
                </Power>
            </Copyright>
        </Section>
    );
};
